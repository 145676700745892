export default function initProductQuiz() {

	const productQuiz = document.querySelector('[data-product-quiz]');

	if(productQuiz == undefined) return;

	setupProductQuiz(productQuiz);
}

function setupProductQuiz( productQuiz ) {

	const testmode = productQuiz.dataset.testmode ?? false;

	window['BarkProductQuiz'] = {
		"points": {},
		"activequizCard": null,
		"emailValid": false,
		"testmode": testmode,
		"complete": false,
	};

	window.quizClose = quizClose;
	window.quizGoBack = quizGoBack;


	const answerButtons = productQuiz.querySelectorAll('.answer-button[data-points]');
	answerButtons.forEach(answerButton => {

		answerButton.addEventListener('click', (event) =>{
			const answerButton = event.currentTarget;

			// It's already selected, so we de-select it
			if(answerButton.classList.contains('selected')) {
				delete window['BarkProductQuiz']['points'][answerButton.id];
				answerButton.classList.remove('selected');
			}
			// Select it
			else {
				let points = JSON.parse(answerButton.dataset.points);
				let title = answerButton.dataset.title ?? '';
				let whyTitle = answerButton.dataset.whyTitle ?? '';
				let data = {points, title, whyTitle};
				window['BarkProductQuiz']['points'][answerButton.id] = data;
				answerButton.classList.add('selected');
			}

			// If we have an answer and it's not a select multiple, so go to the next
			if(answerButton.classList.contains('selected') && answerButton.dataset.selectMultiple != 'true') {
				nextProductQuizCard();
			}
			// Tell our action buttons to update thier state
			else {
				activeActionBarCheck();
			}

			if(window['BarkProductQuiz']['testmode']) updateTestmodeData();

		});
	});

	const skipAnswerButtons = productQuiz.querySelectorAll('[data-skip]');
	skipAnswerButtons.forEach(skipAnswerButton => {
		skipAnswerButton.addEventListener('click', ()=>{
			nextProductQuizCard();
		});
	});

	const quizCards = productQuiz.querySelectorAll('.quiz-card');
	activateProductQuizCard( quizCards[0] );


	const nextButton = productQuiz.querySelector('.next-button');
	if(nextButton) nextButton.addEventListener('click', nextProductQuizCard);


	const signupForm = productQuiz.querySelector('form.quiz-signup-form');
	window['BarkProductQuiz']['signupForm'] = signupForm;
	const emailInput = signupForm.querySelector('input[type="email"]');
	const emailSubmitButton =	productQuiz.querySelector('.actions-bar .action-button.email-valid');

	emailInput.addEventListener('keyup', (event)=> {
		const email = event.currentTarget;
		const validEmail = new RegExp( /^\S+@\S+\.\S+$/ );
		const isValidEmail = !!validEmail.exec(email.value); // Boolean

		window['BarkProductQuiz']['emailValid'] = isValidEmail;
		activeActionBarCheck();
	});

	signupForm.addEventListener('submit', (event)=>{
		event.preventDefault();
  });

	emailSubmitButton.addEventListener('click', ()=>{
		// Skip submit when in testmode
		if(!window['BarkProductQuiz']['testmode']) {
			const form = window['BarkProductQuiz']['signupForm'];
			fetch(form.getAttribute('action'), {
				method: form.getAttribute('method'),
				body: new FormData(form)
			})
			.then(res => res.text())
			.then(data => console.log('signupForm response', data))
			.catch(err => console.error('signupForm error',err))
		}

		nextProductQuizCard();
	});

	const restartButtons = productQuiz.querySelectorAll('.restart-button');
	restartButtons.forEach( restartButton => {
		restartButton.addEventListener('click', () => {
			location.reload();
		});
	})

}

function nextProductQuizCard() {

	const activequizCard = document.querySelector('.quiz-card.active');
	const nextActivequizCard = activequizCard.nextElementSibling;
	if(nextActivequizCard == undefined) {
		console.error('nextActivequizCard missing');
		return;
	}

	setupNext(nextActivequizCard);

	activequizCard.classList.add('deactivating');
	nextActivequizCard.classList.add('activating');

	setTimeout(()=>{
		const prevquizCard = document.querySelector('.quiz-card.deactivating');
		const nextActivequizCard = prevquizCard.nextElementSibling;

		prevquizCard.classList.remove('deactivating');
		prevquizCard.classList.remove('active');

		nextActivequizCard.classList.add('active');
		nextActivequizCard.classList.remove('activating');

		activateProductQuizCard(nextActivequizCard);

	}, 600);

}

function prevProductQuizCard() {

	// If the results have been presented, then restart the quiz
	if(window['BarkProductQuiz']['complete'] && !window['BarkProductQuiz']['testmode']) {
		location.reload();
		return;
	}

	const activequizCard = document.querySelector('.quiz-card.active');
	const prevActivequizCard = activequizCard.previousElementSibling;
	if(prevActivequizCard == undefined) {
		console.error('prevActivequizCard missing');
		return;
	}

	if(prevActivequizCard.classList.contains('quiz-card') == false) {
		// Can't go back
		return false;
	}

	activequizCard.classList.add('deactivating-prev');
	prevActivequizCard.classList.add('activating-prev');

	setTimeout(()=>{
		const prevquizCard = document.querySelector('.quiz-card.deactivating-prev');
		const prevActivequizCard = prevquizCard.previousElementSibling;

		prevquizCard.classList.remove('deactivating-prev');
		prevquizCard.classList.remove('active');

		prevActivequizCard.classList.add('active');
		prevActivequizCard.classList.remove('activating-prev');

		activateProductQuizCard(prevActivequizCard);

	}, 600);

}

function setupNext( nextCard ) {
	if(nextCard.dataset.results || nextCard.querySelector('[data-results]')) {
		setupQuizResults();
	}
}

function activateProductQuizCard(activequizCard) {

	if(activequizCard == undefined) {
		console.error('activateProductQuizCard cannot activate', activequizCard);
		return;
	}

	activequizCard.classList.add('active');

	window['BarkProductQuiz']['activequizCard'] = activequizCard; // Set for global use

	activeProductQuizCardActionsBar();
	activeProductQuizCardBullet();
	activeActionBarCheck();
	activeAutoNext();
}

function activeProductQuizCardActionsBar() {
	const activequizCard = window['BarkProductQuiz']['activequizCard'];
	const actionsBar = activequizCard.dataset.actionsBar ?? '';
	const actionsBarEl = document.querySelector('.actions-bar');

	actionsBarEl.dataset.activeActions = actionsBar;

	if(actionsBar == '') actionsBarEl.classList.remove('active');
	else actionsBarEl.classList.add('active');
}

function activeProductQuizCardBullet() {
	const activequizCard = window['BarkProductQuiz']['activequizCard'];
	const questionProgressBar = document.querySelector('.question-progress-bar');
	if(activequizCard.id == undefined) {
		questionProgressBar.classList.remove('active');
		return;
	}
	const bullet = document.querySelector('.bullet--' + activequizCard.id);
	if(bullet) {
		questionProgressBar.classList.add('active');
		bullet.classList.add('active');
	}
	else {
		questionProgressBar.classList.remove('active');
	}
}

function activeActionBarCheck() {
	const activequizCard = window['BarkProductQuiz']['activequizCard'];
	const actionsBar = activequizCard.dataset.actionsBar ?? ''; // What's current display mode

	if(actionsBar == '') return; // Nothing to do

	const forceAnswer = (activequizCard.dataset.forceAnswer == 'true');

	if(actionsBar == 'next') {
		const nextButton = document.querySelector('.actions-bar .next-button');

		// Look for at least one selected answer from the activequizCard
		const selectedAnswerButton = activequizCard.querySelector('.answer-button.selected');
		const skipButton = activequizCard.querySelector('.skip-button');

		// If we need to forceAnswer and don't have a selectedAnswerButton set disabled
		const disabled = (forceAnswer && !selectedAnswerButton);

		nextButton.disabled = disabled;

		if(skipButton) {
			skipButton.disabled = !disabled;
		}
	}

	if(actionsBar == 'to-results') {
		const nextButton = document.querySelector('.actions-bar .to-results-button.email-valid');
		const emailValid = window['BarkProductQuiz']['emailValid'];

		if(nextButton) {
			nextButton.disabled = !emailValid;
		}
	}

}

function activeAutoNext() {
	const activequizCard = window['BarkProductQuiz']['activequizCard'];
	const autoNext = activequizCard.dataset.autoNext ?? '';

	if(!autoNext) return;

	const seconds = parseInt(autoNext);
	setTimeout(nextProductQuizCard, seconds * 1000);
}

function updateTestmodeData() {
	const testmodeData = document.querySelector('.testmode-data');
	if(!window['BarkProductQuiz']['testmode'] || !testmodeData) return;

	const totals = getPointsTotals()
	let data = '';
	for (const product in totals) {
		data +=  product.toUpperCase() + ': ' + totals[product] +'<br>';
	}
	testmodeData.innerHTML = data;
}

function getPointsTotals() {
	const points = window['BarkProductQuiz']['points'];
	const totals = {};
	for (const answerId in points) {
		for (const product in points[answerId]['points']) {
			if(totals.hasOwnProperty(product) == false) {
				totals[product] = points[answerId]['points'][product];
			}
			else {
				totals[product] = totals[product] + points[answerId]['points'][product];
			}
		}
	}

	return totals;
}


function setupQuizResults() {
	window['BarkProductQuiz']['complete'] = true;
	const points = window['BarkProductQuiz']['points'];
	const pointsTotalsObj = getPointsTotals();

	// Convert points object to a convenient sortable array
	const pointsTotalsArr = [];
	for (const product in pointsTotalsObj) {
		pointsTotalsArr.push({
			product,
			points: pointsTotalsObj[product]
		});
	}

	// Sort by points
	pointsTotalsArr.sort((a, b) => {
    if (a.points < b.points) return 1;
    if (a.points > b.points) return -1;
    return 0;
  });

	// Get the why list items from the winner
	const winner = pointsTotalsArr[0].product;
	const whyItems = [];
	for (const answerId in points) {
		if(points[answerId]['points'][winner] > 0) {

			// Skip and hide from results if whyTitle is 'x'
			if(points[answerId]['whyTitle'].toLocaleLowerCase() == 'x') continue;

			whyItems.push({
				'points': points[answerId]['points'][winner],
				'title': points[answerId]['whyTitle']
			});
		}
	}

	// Desc sort, where larger numbers sort to top
	whyItems.sort((a, b) => {
    if (a.points < b.points) return 1;
    if (a.points > b.points) return -1;
    return 0;
  });


	// Sort product elements by the results, moving productWinner to the top
	const resultsContainer = document.querySelector('[data-results]');
	for(let i = pointsTotalsArr.length - 1; i >= 0; i--) {
		let products = resultsContainer.querySelectorAll('.product');
		let product = resultsContainer.querySelector('#product-'+pointsTotalsArr[i]['product']);
		resultsContainer.insertBefore(product, products[0]);

		// Hide if negative points or no cardsLeftToShow
		if (pointsTotalsArr[i]['points'] < 1) {
			product.classList.add('hidden');
		}
	}


	// Pull in the product-features-list-items from whyItems
	const template = document.querySelector('template#product-features-list-item');
	const templateListItem = template.content;
	const productWinner = resultsContainer.querySelector('#product-'+winner);
	const featuresList = productWinner.querySelector('.product-features-list');

	// Update the results area
	whyItems.forEach(whyItem => {
		const listItem = templateListItem.cloneNode(true);
		const title = listItem.querySelector('.title');
		title.innerHTML = whyItem['title'];
		featuresList.appendChild(listItem);
	});

	// Set productWinner as top-pick
	productWinner.classList.add('top-pick');

	// Hide additional runner-up products if they exceed additionalCount
	const additionalProducts = resultsContainer.querySelectorAll('.product:not(.hidden):not(.top-pick)');
	let additionalCount = parseInt(resultsContainer.dataset.additionalCount);
	if(additionalProducts.length > additionalCount) {
		for(let x = 0; additionalProducts[x]; x++) {
			if(additionalCount) additionalCount--; // skip/continue
			else additionalProducts[x].classList.add('hidden');
		}
	}

	// Position up the 'other products' separator after the winner
	const separator = resultsContainer.querySelector('#additional-separator');
	if(separator) resultsContainer.insertBefore(separator, productWinner.nextElementSibling);
}


function quizClose() {
	history.back();
	// Fallback if no previous URL in history
	setTimeout(()=>{
		window.location	=	window.location.origin;
	}, 2000);
}

function quizGoBack() {
	let result = prevProductQuizCard();
	if(result === false) {
		let leave = confirm('Exit the Product Quiz and go back?');
		if(leave) {
			quizClose();
		}
	}
}
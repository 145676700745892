import AOS from 'aos';
import { setupLogoCarousels } from './components/logoCarousel.js';
import initHeaderNav from './components/header.js';
import { initInview, playMyVideo } from './components/in-view.js';
import { initFeatureCharts, displayCompetitor } from './components/feature-chart.js';
import { initExpandsets } from './lib/Expandset.js';
import { initDefaultGlide, initCardGlide, initVideoShortsGlide, initTabsGlide, goToSlide, initButtonTrack, initFullWidthCarouselBlock } from './components/slider.js';
import { setupModalToggles } from './lib/Modal.js';
import { initPriceToggles } from './components/price-toggle.js';
import { initAutoComplete } from './components/search-autocomplete.js';
import Tabset from './lib/Tabset.js';
import './components/mobile-dropdown.js';
import  { handleAccordionClick } from './components/accordion.js';
import  { getPaginatedPosts, showMoreTechGuides, showMorePostArchive, showMoreQuizzes } from './components/ajax';
import  { selectUpdateURLParam, revealMoreCards, setActionAndSubmit, goToLink, setupGoToMyLink, theContentBreak, initFadeInOnLoad, playIframeByID, touchToClickFix, scrollToByQuerySelector, removeAllClassnamesThenAdd, keypressIntoOnclick, pausePlay, isFullyVisible } from './components/utilities';
import { needsTabindexAdded, initTrackAnalyticsEvent, trackEvent } from './components/event-tracker';
import './components/sticky-toggle.js';
import initStickyScrollTabList from './components/sticky-scroll-tab-list';
import initProductComparisonCardsCarousel from './components/product-comparison-cards-carousel';
import setupStaticCoverageMap from './components/static-coverage-map';
import { initColorShiftInview } from './components/color-fade-in-view';
import { initShrinkyDinkHeading } from './components/shrinky-dink-heading';
import { initToggleFeatureComparison } from './components/toggle-feature-comparison';
import initMinimalFadeCarousel from './components/minimal-fade-carousel';
import { initFloatingLabels } from './components/floating-label';
import { initSignupFormUpdated } from './components/signup-form';
import { initScrollPosition } from './components/scroll-position';
import initProductQuiz from './components/product-quiz';

// add is-loaded class on window.load
window.addEventListener('load', () => {
    document.body.classList.add('is-loaded');
});

window.needsTabindexAdded = needsTabindexAdded;

// init logo carousel
setupLogoCarousels();

// init header nav toggle
initHeaderNav();

// init expandsets
initExpandsets();

//mobile only expandset
if(window.innerWidth < 1096) {
    initExpandsets('[data-mobile-expandset]');
}

// init sliders
initDefaultGlide();
initCardGlide();
initVideoShortsGlide();
initTabsGlide();
initButtonTrack();
initFullWidthCarouselBlock();
window.glideGoToSlide = goToSlide;

// init modals
setupModalToggles('[id*="video-modal"]');
setupModalToggles('[id*="video-block-modal"]');
setupModalToggles('[id*="video-shorts-carousel-modal"]');
setupModalToggles('[id*="email-modal-"]');
setupModalToggles('#static-coverage-map__modal');
setupModalToggles('[id*="search-form-modal"]');
setupModalToggles('[id*="categories-modal"]');
setupModalToggles('[id*="wysiwyg-content-modal"]');
setupModalToggles('[id*="header-modal"]');
setupModalToggles('[id*="popup-modal"]');

//init feature charts
initFeatureCharts();

window.displayCompetitor = displayCompetitor;
window.revealMoreCards = revealMoreCards;
window.selectUpdateURLParam = selectUpdateURLParam;
window.setActionAndSubmit = setActionAndSubmit;
window.playIframeByID = playIframeByID;
window.goToLink = goToLink;
window.scrollToByQuerySelector = scrollToByQuerySelector;
window.removeAllClassnamesThenAdd = removeAllClassnamesThenAdd;
window.keypressIntoOnclick = keypressIntoOnclick;
window.pausePlay = pausePlay;
window.isFullyVisible = isFullyVisible;

setupGoToMyLink();
theContentBreak();
initFadeInOnLoad();
touchToClickFix();
initStickyScrollTabList();
initProductComparisonCardsCarousel();
setupStaticCoverageMap();
initColorShiftInview();
initShrinkyDinkHeading();
initToggleFeatureComparison();
initMinimalFadeCarousel();
initFloatingLabels();
initSignupFormUpdated();
initScrollPosition();
initProductQuiz();

// init price switch
initPriceToggles();

// init tabsets
/* init tabsets */
let tabsets = document.querySelectorAll('[data-tabset]');

tabsets.forEach((tabset) => {
    initCardGlide(`#${tabset.id} [data-tabset-body].open [data-card-carousel-delayed]`);
    new Tabset(tabset, {
        transition_time: 300,
        onTabOpen: function(body) {
            initCardGlide(`[data-tabset-body="${body.dataset.tabsetBody}"] [data-card-carousel-delayed]`);
        }
    });
});

// initialize search autocomplete
initAutoComplete();

// init in view
initInview();
window.playMyVideo = playMyVideo;

window.handleAccordionClick = handleAccordionClick;

window.getPaginatedPosts = getPaginatedPosts;
window.showMoreTechGuides = showMoreTechGuides;
window.showMoreQuizzes = showMoreQuizzes;
window.showMorePostArchive = showMorePostArchive;
window.trackEvent = trackEvent;
initTrackAnalyticsEvent();

AOS.init();

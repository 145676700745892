export function initInview() {
		// If IntersectionObserver not supported, set them all to their inview states
    if(typeof IntersectionObserver === 'undefined') {
        let targets = document.querySelectorAll('[data-inview], .fade-into-view, .drop-fade-into-view, .grow-fade-into-view, .ease-fade-into-view, .autoplay-when-inview, .slide-into-view-ltr, .slide-into-view-rtl');
        targets.forEach(target => {
            target.dataset.inview= true;
						if(entry.target.dataset.inviewAction) {
							eval(entry.target.dataset.inviewAction);
						}
        });

        return;
    }

		let threshold = (window.innerWidth > 767) ? 0.5 : 0.1;

    let options = {
        rootMargin: '0px',
        threshold,
    }

    let setInview = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.dataset.inview = true;
								// Call data-inview-action as function if set
								if(entry.target.dataset.inviewAction) {
									eval(entry.target.dataset.inviewAction);
								}
								if(entry.target.dataset.inviewRepeat !== 'true') {
									observer.unobserve(entry.target);
								}
            }
						else {
							if(entry.target.dataset.inviewRepeat) {
								entry.target.dataset.inview = false;
							}
						}
        });
    }

    let observer = new IntersectionObserver(setInview, options);

    let targets = document.querySelectorAll('[data-inview="false"], .fade-into-view, .drop-fade-into-view, .grow-fade-into-view, .ease-fade-into-view, .autoplay-when-inview, .slide-into-view-ltr, .slide-into-view-rtl');

    targets.forEach(target => {
        observer.observe(target);
    });
}

export function playMyVideo(id) {
	const el = document.getElementById(id);
	if(el == undefined) return;
	const video = (el.tagName == 'VIDEO') ? el : el.querySelector('video');
	if(video == undefined) return;
	if(video) video.play();
}
